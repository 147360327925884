<template>
  <div id="main-wrapper">

    <div class="main-content is-footer">


      <v-app-bar elevation="4" height="65" fixed rounded bottom style="align-items:center;width:94%;right:3%;left:inherit;top:9px;">
        <v-icon  @click="drawer = !drawer" v-if="$store.getters.auth.loggedIn" large > mdi-dots-vertical-circle-outline </v-icon>
        <div id="search-bar" @click="goSearch"  class="search-barw" >
          <v-icon>mdi-magnify</v-icon>&nbsp;
          <span style="color:rgb(112 112 112)"> جستجو در</span> <span style="color:#f2166d;font-weight:bold">&nbsp; سریال ها  </span>
        </div>
      </v-app-bar>
      <v-divider style="margin-top:55px;"></v-divider>
      <!--پرفروشترین ها--->
      <v-container >
        <v-row>
          <v-col >
            <p class="text-center font-medium font-weight-bold pt-3"><v-icon color="black" size="27">mdi-seal</v-icon> کل فرمهای مسابقات جاری </p>
          </v-col>
        </v-row>

        <v-row justify="center">

          <v-dialog
              v-model="dialog"
              max-width="290"
          >
            <v-card>
              <v-card-title  style="font-family: 'iranyekan',tahoma !important;font-size: medium">
                تعداد و مجموع هدایای دوره
              </v-card-title>

              <v-card-text>
               تعداد نفرات برتر بعد از انجام هر بازی بروز میشود
              </v-card-text>

              <v-card-actions>
                <v-card-text v-model="tedad"></v-card-text>
                <v-spacer></v-spacer>
                <v-card-text v-model="forosh"></v-card-text>

              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <v-card v-else :key="key" v-for="(item,key) in form"
                class="mx-auto"
                style="background-color: rgb(255, 255, 135)"

        >
          {{ key+1 }})<span style="font-size: 13px"><b>سریال فرم </b> : {{ item.sh_barge }} </span>
          <span style="font-size: 13px"><b>مبلغ  </b> : {{ item.mablagh }} </span><br>
          <span style="font-size: 13px"><b>دوره مسابقات </b> : {{ item.dore }} </span><span style="font-size: 13px"><b>امتیاز </b> : {{ item.emtiaz }} </span><br><br>
          <div class="flex">
          <v-btn class="flex" @click="$router.push({path:'/formviewphotokol/'+item.sh_barge});" :loading="loading" depressed color="#f799d0" height="30" style="width: 100%"> <b>نمایش فرم</b></v-btn>
          </div>
        </v-card>
        <v-divider style="margin-top:5px;"></v-divider>
        <v-card color="#8f08ea" class="flex">
          <v-btn @click="getform(page);" v-if="pageloading" :loading="loading" depressed :color="randomColor()"  height="50" style="width: 98%;margin:5px;"> <b>نمایش بیشتر</b></v-btn>

        </v-card>
      </v-container>


    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';
import axios from "axios";


export default {
  name: 'photokol',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,

  },
  data () {
    return {
      show: true,
      url:'https://sharjtosharj.com/soccerp/',
      form:[],
      loading:false,
      sh_delete:0,
      page:0,
      pageloading: false,
      dialog: true,
      tedad:0,
      forosh:0
    }
  },
  mounted() {
    let self = this;
    self.form=[];
    self.getform(0);

  },
  computed:{
    ...mapGetters(['baseUrl',"auth"]),
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    goSearch(){
      this.$router.push({path:'/searchphotokol'})
    },
    randomColor() {
      const r = () => Math.floor(400 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },

    getform($page){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("noe", self.$route.params.noe);
      formData.append("Offset", self.page);
      formData.append("Limit", 10);

      axios.post(this.baseUrl+'/photokol.aspx', formData, config)
          .then(function (r) {
            //console.log(r.data.data);
            if(r.data.result=="1"){

              var myarr = r.data.data;

              myarr.forEach((value, index) => {
                self.form.push(value);
              });

              self.pageloading=true;
              if (self.form.length==0)
                self.$router.push({path:'/admin'});
            }
            else
            {self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'red'
            });
              if (self.form.length==0)
              self.$router.push({path:'/admin'});

            }
            self.loading = false;
          });


        self.page=self.page+10;

    },
    handleScroll (event) {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.getform(this.page);
      }
    },

  },
  watch:{}
}
</script>

<style>
.border-grid .col{
  height: 100px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid .col:nth-child(3n + 0){
  border-left: none;
}
.border-grid .col:nth-last-child(-n + 3){
  border-bottom: none;
}
.border-grid .col p{
  font-weight: 600;
}

.border-grid2 .col{
  height: 300px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid2 .col:nth-child(2n + 0){
  border-left: none;
}
.border-grid2 .col:nth-last-child(-n + 2){
  border-bottom: none;
}
.border-grid2 .col p{
  font-weight: 600;
}
.flex {
  display: flex;
}


</style>
